import React from 'react';
import { artistName } from 'utils/artists';
import { ARTIST_DETAILS_ROUTE } from 'utils/routes';
import { GetRadioDetailsQuery } from 'types';

import RadioOrReleaseHeader from '../../Releases/RadioOrReleaseHeader';
import RadioOrReleaseBody from '../../Releases/RadioOrReleaseBody';

interface IRadioPostProps {
  post: GetRadioDetailsQuery['post'];
}

export const Radio: React.FC<IRadioPostProps> = ({ post }) => {
  const {
    coverArt: { fluid },
    title,
    description,
    soundcloudUrl,
    artists,
  } = post;

  return (
    <>
      <RadioOrReleaseHeader
        title={title}
        artist={artistName(artists, false)}
        artistLink={ARTIST_DETAILS_ROUTE(artists[0].slug)}
        image={fluid}
        soundcloudUrl={soundcloudUrl}
        isRadio
      />
      <RadioOrReleaseBody
        description={description}
        artists={artists}
        streamUrl={soundcloudUrl}
      />
    </>
  );
};
