import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { RADIO_DETAILS_SEO } from 'utils/seo';

import Layout from 'components/layout';
import { Radio } from 'components/Radio/Radio';

import { GetRadioDetailsQuery } from 'types';

interface IRadioDetailsProps {
  data: GetRadioDetailsQuery;
  location: Location;
}

const RadioTemplate: React.FC<IRadioDetailsProps> = ({ data }) => {
  const {
    post,
    post: {
      slug,
      coverArt: { fluid },
      title,
      description,
      soundcloudUrl,
    },
  } = data;

  return (
    <Layout
      title={RADIO_DETAILS_SEO.title(title)}
      description={RADIO_DETAILS_SEO.description(
        title,
        get(description, 'childMarkdownRemark.html')
      )}
      image={RADIO_DETAILS_SEO.image(fluid.src)}
      pathname={RADIO_DETAILS_SEO.pathname(slug)}
      audioUrl={RADIO_DETAILS_SEO.audioUrl(soundcloudUrl)}
    >
      <Radio post={post} />
    </Layout>
  );
};

export default RadioTemplate;

export const radioDetailsQuery = graphql`
  query GetRadioDetails($slug: String!) {
    post: contentfulRadio(slug: { eq: $slug }) {
      slug
      publishDate(formatString: "MMMM DD, YYYY")
      title
      coverArt {
        fluid(maxWidth: 450) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          excerpt(pruneLength: 155)
          html
        }
      }
      soundcloudUrl
      artists {
        name
        slug
        images {
          description
          fluid(maxWidth: 180) {
            ...GatsbyContentfulFluid
          }
        }
        description {
          childMarkdownRemark {
            excerpt
          }
        }
      }
    }
  }
`;
